app.controller('ContactController', ['$scope','$http','Config','userJourney', '$rootScope','helper','cart', function($scope,$http,Config, userJourney, $rootScope,helper,cart){
	$scope.message = {};
	$scope.sent = false;
	$scope.cart = cart.getCart();
	$scope.user_journey = userJourney.getJourney();
	if(!localStorage.firstNumber){
		localStorage.setItem("firstNumber", Math.floor((Math.random() * 10) + 1));
		localStorage.setItem("secondNumber",Math.floor((Math.random() * 10) + 1));
	}
	$scope.captcha={
		firstNumber:localStorage.getItem("firstNumber"),
		secondNumber:localStorage.getItem("secondNumber")
	}
	
	$scope.send = function(form,source){

		if(form.$valid){
			helper.showLoader();

			$http({
			  method: 'POST',
			  data: {data: $scope.user_journey},
			  url: Config.base_url+'contactus/save_journey_and_source'
			}).then(function(response) {
					if(response.data.status=="success"){
						var journey_and_source = response.data.data;

						//send an ajax call to save the message and send an email notification
						data = {
								'name':form.name.$modelValue,
								'email':form.email.$modelValue,
								'message':form.message.$modelValue,
								'ip':$('#ip').val(),
								'token':angular.element('#user_token').val(),
								'user-agent': $('#user-agent').val(),
								'brand':Config.brand_key,
								'user-journey-id':journey_and_source.user_journey_id,
								'came-from':journey_and_source.camefrom,
								'utm-campaign':journey_and_source.utm_campaign,
								'utm-medium':journey_and_source.utm_medium,
								'utm-source':journey_and_source.utm_source
							};

						if(source=='contact_page'){
							data.company = form.company.$modelValue;
							data.telephone = form.telephone.$modelValue;
						}

						if(angular.isDefined(form.postcode) && angular.isDefined(form.postcode.$modelValue)){
							data.postcode = form.postcode.$modelValue.toUpperCase();
						}

						if($scope.cart.content.length>0){
							data.cart = $scope.cart.content;
						}

						$http({
						method: 'POST',
						data:data,
						url: Config.api_url+'message',
						headers:{
							'authorization':Config.api_key
						}
						}).then(function(response) {
							$scope.sent = true;
							//reset form 
							form.$setPristine();
							$scope.message = {}
							form.$setUntouched();
							helper.hideLoader();

							if(source!='contact_page'){
								//show top notification
								display_top_alert('success','Contact message successfully sent!');
							}
							//trigger google tracking
							// ga('send', 'pageview', {page: '/contact.html'});
							gtag('event', 'page_view', {page_location: Config.base_url + 'contact.html'});
						}, function(response) {
							console.log(response);
						});}
					else{
						console.log(response);
						display_top_alert('danger','Contact message could not be sent!');
					}
			   	
			  }, function(response) {
			    console.log(response);
			 });	
		}
	}

	$scope.clear = function(form){
		//reset form 
	   	form.$setPristine();
	   	$scope.message = {}
	   	form.$setUntouched();
	}
}])